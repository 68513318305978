/* eslint camelcase: 0 */
import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import { useLazyQuery, useQuery } from '@apollo/client';
import BackButton from '../components/ui/BackButton';
import Button from '../components/ui/Button';
import ButtonGroup from '../components/ui/ButtonGroup';
import InputGroup from '../components/ui/InputGroup';
import InputSelect from '../components/ui/InputSelect';
import InputSelectOption from '../components/ui/InputSelectOption';
import StepperLayout from '../components/layouts/StepperLayout';
import {
  GET_PLANS_BY_RETAILERS_SUPPLYAREA,
  GET_RETAILERS,
} from '../apollo/queries';
import {
  GetRetailersBySupplyarea,
  GetRetailersBySupplyarea_getRetailersBySupplyarea,
  GetRetailersBySupplyareaVariables,
} from '../apollo/__generated__/GetRetailersBySupplyarea';
import {
  getCurrentPlanLS,
  getCurrentRetailer,
  getHasSolarSystem,
  getSelectedDistributorLS,
  setCurrentPlan,
  setCurrentRetailer,
  yesNoToBool,
} from '../utils/localStorage/localStorageFunctions';
import {
  GetPlans,
  GetPlans_getPlansByRetailerSupplyarea,
  GetPlansVariables,
} from '../apollo/__generated__/GetPlans';
import { DEFAULT_PLAN_FILTERS } from '../utils/calculationUtils';
import { navigateAndSetActiveStep } from '../utils/utils';
import { StepUrl } from '../utils/enums';
import usePreloadPageData from '../hooks/usePreloadPageData';
import SEOLayout from '../components/layouts/SEOLayout';

const CurrentRetailerSchema = Yup.object().shape({
  retailerId: Yup.string().required('Please specify your retailer'),
  planId: Yup.string().required('Please specify your plan name'),
});
// TODO: reset on household/business change
const RetailerChoice = () => {
  const [retailers, setRetailers] = useState<
    (GetRetailersBySupplyarea_getRetailersBySupplyarea | null)[] | null
  >();

  const [plans, setPlans] = useState<
    (GetPlans_getPlansByRetailerSupplyarea | null)[] | null
  >();

  //
  // Retailers
  //

  const supplyareaId = getSelectedDistributorLS()?.supplyarea?.id ?? '';
  const planFilters = DEFAULT_PLAN_FILTERS;

  const { data: retailersData, loading: retailersLoading } = useQuery<
    GetRetailersBySupplyarea,
    GetRetailersBySupplyareaVariables
  >(GET_RETAILERS, { variables: { supplyareaId, planFilters } });

  useEffect(() => {
    if (retailersData && !retailersLoading) {
      const retailersScrambled = retailersData.getRetailersBySupplyarea;
      const retailersSorted = retailersScrambled
        ?.slice()
        ?.sort((retailerA, retailerB) => {
          const orgNameA = retailerA?.orgName?.toLowerCase() ?? '';
          const orgNameB = retailerB?.orgName?.toLowerCase() ?? '';
          // sort string ascending
          if (orgNameA < orgNameB) return -1;
          if (orgNameA > orgNameB) return 1;
          return 0; // default return value (no sorting)
        });
      setRetailers(retailersSorted);
    }
  }, [retailersData, retailersLoading]);

  //
  // Retailer plans
  //

  const [getPlans, { data: plansData, loading: loadingData }] = useLazyQuery<
    GetPlans,
    GetPlansVariables
  >(GET_PLANS_BY_RETAILERS_SUPPLYAREA);

  const getPlansByRetailer = (selectedRetailerId: string) => {
    getPlans({
      variables: { supplyareaId, planFilters, retailerId: selectedRetailerId },
    });
  };

  useEffect(() => {
    if (plansData && !loadingData) {
      setPlans(plansData.getPlansByRetailerSupplyarea);
    }
  }, [plansData, loadingData]);

  const [initialFormValues] = useState(() => {
    const retailerLS = getCurrentRetailer();
    const planLS = getCurrentPlanLS();

    return { retailerId: retailerLS?.id || '', planId: planLS?.planId || '' };
  });

  const handleNext = (values?: typeof initialFormValues) => {
    if (!values) {
      setCurrentRetailer(null);
      setCurrentPlan(null);
      navigateAndSetActiveStep(StepUrl.URL_RESULTS, {
        state: { resendQueries: true, resetFilters: true },
      });
      return;
    }

    const currentRetailer = retailers?.find(
      (retailer) => retailer?.id === values.retailerId
    );
    const currentPlan = plans?.find((plan) => plan?.id === values.planId);

    if (currentRetailer && currentPlan) {
      setCurrentRetailer(currentRetailer);
      setCurrentPlan(currentPlan);
    }

    navigateAndSetActiveStep(StepUrl.URL_RESULTS, {
      state: { resendQueries: true, resetFilters: true },
    });
  };

  const handleBack = () => {
    const hasSolar = getHasSolarSystem();

    if (yesNoToBool(hasSolar)) {
      navigateAndSetActiveStep(StepUrl.URL_SOLAR_SYSTEM_DETAILS);
      return;
    }

    navigateAndSetActiveStep(StepUrl.URL_SOLAR_INSTALLED);
  };

  usePreloadPageData(
    StepUrl.URL_RESULTS,
    StepUrl.URL_SOLAR_SYSTEM_DETAILS,
    StepUrl.URL_SOLAR_INSTALLED
  );

  return (
    <SEOLayout>
      <StepperLayout
        isLoading={retailersLoading}
        currentStage={2}
        currentStep={12}
        pageUrl={StepUrl.URL_RETAILER_CHOICE}
      >
        <div className="group my-3">
          <Formik
            initialValues={initialFormValues}
            onSubmit={(values) => {
              handleNext(values);
            }}
            validationSchema={CurrentRetailerSchema}
          >
            {(props) => {
              useEffect(() => {
                if (props.values.retailerId === "") {
                  return;
                }

                getPlansByRetailer(props.values.retailerId);

                if (props.values.retailerId === getCurrentRetailer()?.id)
                  return;

                props.setValues({ ...props.values, planId: '' }, false);
              }, [props.values.retailerId]);

              return (
                <Form>
                  <h2 className="my-2">Who is your current retailer?</h2>
                  <p className="sub-heading">
                    This is optional but the more information you provide the
                    more accurate the savings we can calculate for you.
                  </p>
                  <InputGroup className="my-2">
                    <p className="text-label" id="ret-label">
                      Who is your retailer?
                    </p>
                    <InputSelect
                      name="retailerId"
                      id="ret-select"
                      placeholder="Retailer name"
                      initialValueText={getCurrentRetailer()?.orgName}
                      aria-labelledby="ret-label ret-select"
                    >
                      {retailers?.map((retailer) => (
                        <InputSelectOption
                          value={retailer?.id ?? ''}
                          key={retailer?.id ?? ''}
                        >
                          {retailer?.orgName}
                        </InputSelectOption>
                      ))}
                    </InputSelect>
                  </InputGroup>
                  {props.values.retailerId && (
                    <InputGroup className="my-2">
                      <p className="text-label" id="plan-label">
                        What&apos;s your plan name?
                      </p>
                      <InputSelect
                        name="planId"
                        id="plan-select"
                        placeholder="Plan name"
                        aria-labelledby="plan-label plan-select"
                        initialValueText={getCurrentPlanLS()?.name}
                      >
                        {plans?.map((plan) => (
                          <InputSelectOption
                            value={plan?.id ?? ''}
                            key={plan?.id}
                          >
                            {plan?.name}
                          </InputSelectOption>
                        ))}
                      </InputSelect>
                    </InputGroup>
                  )}
                  <ButtonGroup className="mt-5">
                    <Button
                      type="button"
                      variant="tertiary"
                      onClick={() => {
                        handleNext();
                      }}
                    >
                      Skip
                    </Button>
                    <Button
                      type="submit"
                      disabled={
                        props.values.planId === '' ||
                        props.values.retailerId === ''
                      }
                    >
                      Next
                    </Button>
                  </ButtonGroup>
                </Form>
              );
            }}
          </Formik>
        </div>
        <div className="my-3">
          <BackButton text="Back" onClick={handleBack} />
        </div>
      </StepperLayout>
    </SEOLayout>
  );
};

export default RetailerChoice;
